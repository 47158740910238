/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { IValueStreamConfiguration } from '@bees-web/nfa-types'
import { Reducer } from 'react'
import dependencies from '../../config'

export type ConfigureReducer = (
  configuration: IValueStreamConfiguration
) => Reducer<any, any>

export type GetReducersFromDependencies = (configure: ConfigureReducer) => {
  [key: keyof typeof dependencies]: Reducer<any, any>
}

const getReducersFromDependencies: GetReducersFromDependencies = (configure) =>
  Object.keys(dependencies).reduce((reducers, key) => {
    const configuration = dependencies[key]
    return {
      ...reducers,
      [configuration.namespace]: configure(configuration),
    }
  }, {})

export default getReducersFromDependencies
