import useInteractiveGlobal from '@bees-web/nfa-interactive-global/interactive-map'
import useInteractiveLinkGlobal from '@bees-web/web-interactive-link-global/interactive-map'
import { IApplicationState } from '@bees-web/nfa-types'
import { Dispatch } from 'react'
import { IInteractiveMap } from '../blocks/interfaces'
import { GlobalNamespaces } from '../interfaces'

interface InteractiveGlobalMapArgs {
  dispatch: Dispatch<any>
  state: IApplicationState
  globalNamespace: string
}

type InteractiveGlobalMap = (args: InteractiveGlobalMapArgs) => IInteractiveMap

export const interactiveGlobalMap: InteractiveGlobalMap = ({
  dispatch,
  state: STATE,
  globalNamespace = GlobalNamespaces.DEFAULT,
}) => {
  const { selectedInteractive, useState } = {
    default: {
      selectedInteractive: useInteractiveGlobal,
      useState: STATE.globals,
    },
    link: {
      selectedInteractive: useInteractiveLinkGlobal,
      useState: STATE.globals,
    },
  }[globalNamespace]

  return selectedInteractive(dispatch, useState)
}
