import { Segment } from '@bees-web/nfa-types'

export const nonProd: Segment = {
  AR: {
    key: 'XOJNzXscBqjg3Bmldp5QnW1MefcQkRla',
    host: 'https://api.segment.io',
  },
  BE: {
    key: 'PF3Ee9HeaF21LQBOgnryRih7JXck1wyR',
    host: 'https://events.eu1.segmentapis.com',
  },
  BO: {
    key: 'iLa9kagwgtJQTHCH2oiuvhLvx7DX2XT3',
    host: 'https://api.segment.io',
  },
  BR: {
    key: 'pjjXk3RFbZDmlDn5INy50glJmwPWQITd',
    host: 'https://api.segment.io',
  },
  CA: {
    key: 'KQ1ZL6XNeldKXs4v2BbIuzoeucVAB4qy',
    host: 'https://api.segment.io',
  },
  CL: {
    key: '0bzSt5nl7cAkrDDcvIcGhDEH1FOzAgFq',
    host: 'https://api.segment.io',
  },
  CO: {
    key: 'baucxmLEJpaTy5nOmnwBbL3q5VcHbFQ6',
    host: 'https://api.segment.io',
  },
  DE: {
    key: 'q8CeEREMIua90sBUCsW3UpRurFUcJyay',
    host: 'https://events.eu1.segmentapis.com',
  },
  DO: {
    key: 'EWItkHoiDuDTi0o2FraZ9lvSkY6MpYuB',
    host: 'https://api.segment.io',
  },
  EC: {
    key: 'p9Mh4A8HhOAsuHCVWih5QHnIdhce9n0R',
    host: 'https://api.segment.io',
  },
  ES: {
    key: '1ryXLoozVrIscagACuoPuRoyWhTnC6tC',
    host: 'https://events.eu1.segmentapis.com',
  },
  GB: {
    key: 'pIGE7qmjUaUq3jxfxQjI74gUBXcHVLiL',
    host: 'https://events.eu1.segmentapis.com',
  },
  HN: {
    key: '7nihCb30DRt4W98ZyH8gdtSNpYbeApAr',
    host: 'https://api.segment.io',
  },
  KR: {
    key: 'nCI7XLvB3Ge4yzDrxCdfUKXyJWHs6KmM',
    host: 'https://api.segment.io',
  },
  MX: {
    key: 'myjo3aEkiOCvIYw9zkz9osSesaON44Ek',
    host: 'https://api.segment.io',
  },
  NL: {
    key: 'ungQERzRgTkT0ZIV2EchaFDaUSzP3gJ4',
    host: 'https://events.eu1.segmentapis.com',
  },
  PA: {
    key: 'w9zQV2O3a23zVHyuAcg5QMQTuM3q9jCr',
    host: 'https://api.segment.io',
  },
  PE: {
    key: 'MekBPILzFzPnZUdybMUqGR0ZtD2sjAP4',
    host: 'https://api.segment.io',
  },
  PY: {
    key: 'NWARGUvBALw2gL1L0fergF2qh6qSYyDw',
    host: 'https://api.segment.io',
  },
  SV: {
    key: '1mEFokKITlUMtwziLaYwN1V32yjWIgaX',
    host: 'https://api.segment.io',
  },
  US: {
    key: 'fyxxxE15gKP4nBxhhEdZAMCFyGPKON20',
    host: 'https://api.segment.io',
  },
  UY: {
    key: 'QJZzqsdp1SBoTEcvCf52lRqfWlcSklzB',
    host: 'https://api.segment.io',
  },
  ZA: {
    key: 'RrdjVnzBRqahrw5ApQNyh9e4xdWrS1D3', // <- ZA SAB Connect; ZA BEES NON PROD -> 'ehstaPGTHibGaY7vJIx7hcTHS77cHMwN'
    host: 'https://api.segment.io',
  },
}
