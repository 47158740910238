import Script from 'next/script'
import React from 'react'
import Cookies from 'js-cookie'

type FnParams = {
  allowTracker: boolean
  newRelicString: string
}

const newRelicTrackerScript = ({
  allowTracker,
  newRelicString,
}: FnParams): JSX.Element | null => {
  const newRelicAvailable =
    process.env.NODE_ENV !== 'development' &&
    !!process.env.NEW_RELIC_BROWSER_LICENSE_KEY &&
    !!process.env.NEW_RELIC_APPLICATION_ID

  const beesApplication = Cookies.get('APPLICATION')

  return newRelicAvailable && allowTracker ? (
    <Script
      dangerouslySetInnerHTML={{
        __html: `${newRelicString}
        ;NREUM.loader_config=${JSON.stringify({
          accountID: process.env.NEW_RELIC_ACCOUNT_ID,
          trustKey: process.env.NEW_RELIC_TRUST_KEY,
          agentID: process.env.NEW_RELIC_AGENT_ID,
          licenseKey: process.env.NEW_RELIC_BROWSER_LICENSE_KEY,
          applicationID: process.env.NEW_RELIC_APPLICATION_ID,
        })}
        ;NREUM.info=${JSON.stringify({
          beacon: 'bam.nr-data.net',
          errorBeacon: 'bam.nr-data.net',
          licenseKey: process.env.NEW_RELIC_BROWSER_LICENSE_KEY,
          applicationID: process.env.NEW_RELIC_APPLICATION_ID,
          sa: 1,
          beesApplication,
        })}
        ;NREUM.setCustomAttribute("beesApplication", "${beesApplication}");`,
      }}
    />
  ) : null
}

export default newRelicTrackerScript
