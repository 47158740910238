/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'
import { storage } from '../utils/createWebStorage'

import checkout from '@bees-web/nfa-interactive-checkout/reducers'
import globals, {
  blackList as globalBlackList,
} from '@bees-web/nfa-interactive-global/reducers'
import payments from '@bees-web/nfa-interactive-payments/reducers'
import customerExperience, {
  blackList as customerExperienceBlackList,
} from '@bees-web/nfa-interactive-customer-experience/reducers'
import home, {
  blackList as homeBlackList,
} from '@bees-web/nfa-interactive-home/reducers'
import search from '@bees-web/nfa-interactive-search/reducers'
import cart from '@bees-web/nfa-interactive-cart/reducers'
import link, {
  blackList as linkBlackList,
} from '@bees-web/nfa-interactive-link/reducers'
import products, {
  blackList as productsBlackList,
} from '@bees-web/nfa-interactive-products/reducers'
import deals from '@bees-web/nfa-interactive-deals/reducers'
import productOrder from '@bees-web/nfa-interactive-product-order/reducers'
import campaigns from '@bees-web/nfa-interactive-campaigns/reducers'
import euFinance from '@bees-web/web-eu-finance/reducers'
import { HYDRATE } from 'next-redux-wrapper'
import { Reducer } from 'react'
import { deepMerge } from '../utils/deepMerge'
import { getReducersFromDependencies } from '../utils/VL'
import { ConfigureReducer } from '../utils/VL/getReducersFromDependencies'

const configPersistedReducer = (
  key: string,
  version: number,
  blacklist?: string[],
  reducer?: any,
  additionalConfig?: { [key: string]: any }
) =>
  persistReducer(
    {
      key: `new-web-react-${key}-${version}`,
      storage,
      blacklist,
      version,
      ...additionalConfig,
    },
    reducer
  )

// Should be used together client-server redux intended actions
const ssrReconciler = <T>(
  reducer: Reducer<T, any>,
  selector: (payload: any) => any,
  hydrate?: (state, payload, deepMerge) => any
): Reducer<T, any> => {
  return (state, action): T => {
    if (action.type === HYDRATE) {
      const payload = selector(action.payload)

      // If there is no set hydrate handler client-server state will be deep merged
      if (hydrate) return { ...hydrate(state, payload, deepMerge) }
      return { ...state, ...payload } //deepMerge(payload, state)
    }
    return reducer(state, action)
  }
}

// Use to configure from ValueStreamConfiguration
const configureReducer =
  (version: number): ConfigureReducer =>
  (configuration) => {
    const { reducer, persist, serverReconciler } = configuration.state

    const ssrReducer = ssrReconciler(
      reducer,
      (payload) => payload[configuration.namespace],
      serverReconciler
    )

    return persist
      ? persistReducer(
          {
            storage,
            ...persist(version),
          },
          ssrReducer
        )
      : ssrReducer
  }

const getGlobalState = (version: number) =>
  configPersistedReducer(
    'globals',
    version,
    globalBlackList,
    ssrReconciler(
      globals,
      (payload) => payload.globals,
      (state, payload) => ({
        ...state,
        cache: {
          ...payload.cache,
          pages: {
            ...payload.cache.pages,
            ...state.cache.pages,
          },
          deferred: state.cache.deferred,
          selectedPocAccount: state.cache.selectedPocAccount,
          storeId: state.cache.storeId,
          activeCacheKey: state.cache.activeCacheKey,
          meta: {
            ...payload.cache.meta,
            ...state.cache.meta,
          },
        },
        user: payload.user,
      })
    ) // Apply client-server reconcilier
  )

export default (version: number) =>
  combineReducers({
    checkout,
    globals: getGlobalState(version),
    customerExperience: configPersistedReducer(
      'customer-experience',
      version,
      customerExperienceBlackList,
      customerExperience
    ),
    home: configPersistedReducer('home', version, homeBlackList, home),
    search: configPersistedReducer('search', version, [], search),
    cart,
    link: configPersistedReducer('link', version, linkBlackList, link),
    products: configPersistedReducer(
      'products',
      version,
      productsBlackList,
      products
    ),
    deals,
    payments,
    productOrder,
    campaigns: configPersistedReducer('campaigns', version, [], campaigns),
    euFinance,
    ...getReducersFromDependencies(configureReducer(version)),
    linkGlobal: getGlobalState(version),
  })
