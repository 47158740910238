import { IConfigType } from '../interfaces/index'
import { prod as segment } from './segment'
import { prod as oneTrustKeys } from './oneTrust'

export const prod: IConfigType = {
  segment,
  oneTrustKeys,
  brazeKeys: {
    AR: 'bfd7f424-bca3-4344-b057-1186c78f114b',
    BE: '',
    BO: 'a41b8997-b07e-4102-9984-74fc33edfb8f',
    BR: 'abe71a67-d024-4bc0-85ff-6d836b63e98c',
    CA: '4c2e9cb6-771d-4cdb-a6ee-0c186e5e2020',
    CL: '',
    CO: '6a3219e6-1c42-4542-9ce6-3b736142d774',
    DE: 'a8f61921-cb79-49ac-a740-8d7aec5a14cb',
    DO: '70499f1f-db84-4f79-9219-efc543e0abc1',
    EC: '73f1b038-216f-4b96-bb85-a60440b43c1f',
    ES: '',
    GB: '',
    HN: 'f72c23c2-715a-423a-a044-81db05f78ad9',
    KR: '826adeb0-3d9b-4fcc-a339-726667131b38',
    MX: 'df8ef293-34e0-4077-b435-bf0d8884d84e',
    NL: '',
    PA: 'e48b0913-5ead-4f64-9fc3-ed906a91fc10',
    PE: '349c83b3-d0ad-4534-b905-2f2acbd5e22d',
    PY: '1d92119f-090f-421a-9f19-5089d3e38dca',
    SV: 'b7fa9310-85a1-4ad1-95f6-6bc589170d12',
    US: '56320c62-95cb-4717-b3fb-7bcef4679e66',
    UY: '680d8cdd-4711-4579-aa03-ad6779775b3e',
    ZA: 'ab7f1625-0a34-4233-b909-89b32a22592b',
  },
  googleApiKeys: {
    AR: '',
    BR: 'AIzaSyBNagvAant5YUvzVUD2TCPMK8zE90TieRk',
    CL: '',
    CO: 'AIzaSyB6pPchpopPG3n0Ee0krQRRsE632zFIpOY',
    DO: '',
    EC: 'AIzaSyBO9Q8NwPrmIQ0iJBZH5jEZIYKUBVsBi6Q',
    MX: 'AIzaSyDJeOEK9tblB765S62Fq7JovGzBPn_es1U',
    PA: '',
    PE: 'AIzaSyAAlAIeD-uyW7-KD3GAPU94mUzm-jUKIBQ',
    PY: '',
    US: '',
    ZA: '',
  },
  baseUrl: {
    AR: 'sdk.iad-03.braze.com',
    BE: 'sdk.fra-02.braze.eu',
    BO: 'sdk.iad-03.braze.com',
    BR: 'sdk.iad-03.braze.com',
    CA: 'sdk.iad-03.braze.com',
    CL: 'sdk.iad-03.braze.com',
    CO: 'sdk.iad-03.braze.com',
    DE: 'sdk.fra-02.braze.eu',
    DO: 'sdk.iad-03.braze.com',
    EC: 'sdk.iad-03.braze.com',
    ES: 'sdk.fra-02.braze.eu',
    GB: 'sdk.fra-02.braze.eu',
    HN: 'sdk.iad-03.braze.com',
    KR: 'sdk.iad-03.braze.com',
    MX: 'sdk.iad-03.braze.com',
    NL: 'sdk.fra-02.braze.eu',
    PA: 'sdk.iad-03.braze.com',
    PE: 'sdk.iad-03.braze.com',
    PY: 'sdk.iad-03.braze.com',
    SV: 'sdk.iad-03.braze.com',
    US: 'sdk.iad-06.braze.com',
    UY: 'sdk.iad-03.braze.com',
    ZA: 'sdk.iad-03.braze.com',
  },
  env: 'prod',
}
