import { createStore, applyMiddleware, Middleware, Store } from 'redux'
import thunk from 'redux-thunk'
import trackableActions from './constants/trackableActions'
import segmentTracking from '@bees-web/nfa-interactive-global/middlewares/segment'
import webLinkCart from '@bees-web/web-interactive-link-cart/middlewares/index'
import { composeWithDevTools } from '@redux-devtools/extension'
import { persistReducer } from 'redux-persist'
import { storage } from '../utils/createWebStorage'
import { createWrapper, MakeStore } from 'next-redux-wrapper'

//import { serialize, deserialize } from 'json-immutable'

import reducers from './reducers'

import { IWindow, IGlobal } from '../interfaces'

declare let window: IWindow
declare let global: IGlobal

// theses reducers persist are configured separately in ./reducers
const middleware: Middleware[] = [
  thunk,
  segmentTracking(trackableActions),
  webLinkCart(),
]
const version = parseInt(process.env.BUILD_BUILDID)

export const makeStore: MakeStore<Store> = () => {
  return createStore(
    persistReducer(
      {
        key: `new-web-react-${version}`,
        storage,
        blacklist: [
          'globals',
          'account',
          'home',
          'cart',
          'checkout',
          'customerExperience',
          'link',
          'products',
          'deals',
          'payments',
          'productOrder',
          'campaigns',
          'finances',
          'linkGlobal',
        ],
        version,
      },
      reducers(version)
    ),
    {},
    composeWithDevTools({
      actionsDenylist: [
        'RENDER_DEFERRED_FROM_CACHE',
        'JOKER_DEFERRED_REQUEST_MADE',
        'JOKER_DEFERRED_RESPONSE_SAVED',
      ],
    })(applyMiddleware(...middleware))
  )
}

// export an assembled wrapper
const wrapper = createWrapper<Store>(makeStore, {
  debug: false,
  serializeState: (state) => JSON.stringify(state), // Added to omit 'undefined' attribute values
  deserializeState: (state) => {
    try {
      return JSON.parse(state)
    } catch (e) {
      return state
    }
  },
})

export default wrapper
