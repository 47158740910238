/* eslint-disable security/detect-object-injection */
import { IGlobalActions } from '@bees-web/nfa-types'
import { deepMerge } from '../utils/deepMerge'
import * as authentication from '@bees-web/nfa-interactive-global/actions/authentication'
import * as cache from '@bees-web/nfa-interactive-global/actions/cache'
import * as cart from '@bees-web/nfa-interactive-global/actions/cart'
import * as appActions from '@bees-web/nfa-interactive-global/actions/appActions'
import * as header from '@bees-web/nfa-interactive-global/actions/header'
import * as requester from '@bees-web/nfa-interactive-global/actions/requester'
import * as user from '@bees-web/nfa-interactive-global/actions/user'
import * as products from '@bees-web/nfa-interactive-global/actions/products'
import { getSharedActions } from '../utils/VL'

// Keep this deepmerging (deepMerge()) until all VL are been migrated to the new VL configuration approach
export const globalActions: IGlobalActions = deepMerge(getSharedActions(), {
  appActions,
  authentication,
  cache,
  cart,
  header,
  requester,
  user,
  products,
})
