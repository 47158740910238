import fetch from 'isomorphic-unfetch'
import cookies from 'next-cookies'
import { getHost } from './getHost'
import { getCookieHeader } from './getCookieHeader'
import auth from '../routing/auth.json'

export type CheckDeeplink = (context: any) => Promise<void>

export const checkDeeplink: CheckDeeplink = async (context) => {
  try {
    const { req, query: q, res } = context || {}
    const { host } = req?.headers
    const instancePrefix = process.env.INSTANCE_PREFIX ?? ''

    const { status } = await fetch(
      `${getHost(host)}/${instancePrefix}api/auth/check`,
      {
        headers: {
          Cookie: getCookieHeader(cookies(context)),
          activeCluster: process.env.ENV_COLOR,
        },
      }
    )

    const { asPath } = context
    const [redirect_path, params] = asPath.split('?')
    const requiresAuth = auth[redirect_path] === undefined ? true : false

    const query = new URLSearchParams({
      redirect_path,
      ...Object.fromEntries(new URLSearchParams(params)),
    })

    if (status === 200 && q.store) {
      res.writeHead(302, { Location: `/api/deeplink?${query.toString()}` })
      res.end()
    }

    if (status === 401 && requiresAuth) {
      res.writeHead(302, {
        Location: `/${instancePrefix}login?${query.toString()}`,
      })
      res.end()
    }
  } catch (e) {
    console.error(
      'Deeplink',
      JSON.stringify({
        error: e.message,
      })
    )
  }
}
