/* eslint-disable security/detect-object-injection */
import { IGlobalActions } from '@bees-web/nfa-types'
import dependencies from '../../config'

// This method get all shared actions from all dependencies
const getSharedActions = (): IGlobalActions => {
  const actions = {}

  Object.keys(dependencies).forEach((key) => {
    const { public: publicActions = {} } = dependencies[key].actions
    actions[key] = publicActions
  })

  return actions
}

export default getSharedActions
