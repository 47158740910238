import Script from 'next/script'
import React from 'react'

const oneTrustConfigurationScript = (
  oneTrustValue: any,
  country: string,
  setOneScriptTrustLoaded: React.Dispatch<React.SetStateAction<boolean>>
): JSX.Element | null => {
  const oneTrustConfiguration =
    oneTrustValue && typeof oneTrustValue === 'object'
      ? oneTrustValue
      : {
          key: oneTrustValue,
          autoBlock: false,
          showCookieSettings: false,
        }

  return oneTrustConfiguration.key ? (
    <>
      {oneTrustConfiguration.autoBlock && (
        <Script
          id="cdn-cookie-law"
          src={`https://cdn.cookielaw.org/consent/${oneTrustConfiguration.key}/OtAutoBlock.js`}
        />
      )}
      <Script
        data-document-language={country === 'CA'}
        data-domain-script={oneTrustConfiguration.key}
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        onLoad={(): void => {
          setTimeout(() => {
            setOneScriptTrustLoaded(true)
          }, 1000)
        }}
      />
      <Script
        dangerouslySetInnerHTML={{
          __html: `function OptanonWrapper(){}`,
        }}
      />
    </>
  ) : null
}

export default oneTrustConfigurationScript
