import { useEffect, useState } from 'react'
import Cookies from 'js-cookie'

const CATEGORY_TRACKER = '4'
const ENABLE_TRACKING = 'enable_tracking'

const TRACKERS_COOKIES = ['JSESSIONID', 'ajs_user_id', 'ajs_anonymous_id']

const removeCookies = (cookies: string[]): void => {
  cookies.forEach((cookie) => {
    Cookies.remove(cookie, { path: '/' })
  })
}

const useOneTrust = (
  oneScriptTrustLoaded: boolean,
  oneTrustValue = { couldBlockerTrackers: false }
): { allowTracker: boolean } => {
  const [allowTracker, setAllowTracker] = useState(() => {
    return Cookies.get(ENABLE_TRACKING) === 'true'
  })

  const { couldBlockerTrackers } = oneTrustValue

  useEffect(() => {
    try {
      const checkTrackerCookie = () => {
        const trackerEvents =
          (window as any).OnetrustActiveGroups?.indexOf(CATEGORY_TRACKER) > -1
        setAllowTracker(trackerEvents)
      }

      const pollOneTrust = () => {
        if (
          typeof (window as any).OneTrust !== 'undefined' &&
          typeof (window as any).OnetrustActiveGroups !== 'undefined'
        ) {
          checkTrackerCookie()
          ;(window as any).OneTrust?.OnConsentChanged(checkTrackerCookie)
        }
      }

      pollOneTrust()
    } catch (e) {
      console.error(
        'dev',
        JSON.stringify({
          error: e.message,
        })
      )
    }
  }, [oneScriptTrustLoaded])

  useEffect(() => {
    if (!couldBlockerTrackers) {
      Cookies.set(ENABLE_TRACKING, true)
      return
    }

    if (!allowTracker) {
      removeCookies(TRACKERS_COOKIES)
    }

    Cookies.set(ENABLE_TRACKING, allowTracker)
  }, [allowTracker, couldBlockerTrackers])

  if (!couldBlockerTrackers) {
    return {
      allowTracker: true,
    }
  }

  return {
    allowTracker,
  }
}

export default useOneTrust
