import { updateUser } from '@bees-web/nfa-interactive-global/actions/user'
import { languages } from '@bees-web/nfa-interactive-global/utils/getLocale'

export const getCountryAndLanguage = (context) => {
  const { store } = context
  const { globals } = store.getState()

  let { country, language } = context.query

  if (country && typeof country !== 'string') {
    country = country[0]
  }

  if (language && typeof language !== 'string') {
    language = language[0]
  }

  if (country) {
    country = country.toUpperCase()
    language = language ? language.toLowerCase() : languages[country][0]
    store.dispatch(
      updateUser({
        country,
        language,
      })
    )
  } else {
    country = globals.user.country
    language = globals.user.language
    country = country?.toUpperCase()
  }

  return { country, language }
}
