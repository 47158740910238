import { IConfigType } from '../interfaces/index'
import { nonProd as segment } from './segment'
import { nonProd as oneTrustKeys } from './oneTrust'

export const sit: IConfigType = {
  segment,
  oneTrustKeys,
  brazeKeys: {
    AR: 'a000bee8-fbf8-4082-b20d-a1056d0bacd7',
    BE: '8fafc22c-58bd-40ce-ba75-9ccca20f1917',
    BO: '39385334-d5f0-41b5-b389-982bf59c7775',
    BR: 'cdc12249-60ba-40cf-acd8-d3a61973e0c7',
    CA: '6b559bf5-fa44-4e81-84c0-f21a21d67785',
    CL: '',
    CO: '42714228-3d3d-46f5-b681-280ce0579a53',
    DE: '02137957-6df5-4d48-8cd6-2c5b4f444ebc',
    DO: '527014e3-e53f-4749-a2b9-12d4456a3da4',
    EC: '1c59340e-8322-4245-b868-3af23233bb31',
    ES: 'f459f30d-eb6e-4845-a041-fea0d9978f72',
    GB: 'e0c7f4de-3baf-447f-a050-4054864d2564',
    HN: '73064aba-225b-4760-b364-44298b6dea79',
    KR: '27e2265e-d525-4998-b506-e70913687451',
    MX: '0a02d51b-c65e-44bd-9c42-56700dbe3e89',
    NL: '4f404e93-c64b-4d11-99d5-6bae3d60a1a0',
    PA: '8b9172d0-e751-42a5-b475-9fe08a3ce35b',
    PE: '7b3a546a-da51-42da-86e4-de56dc928b6a',
    PY: '65dc402e-1d86-43a8-b622-f267561cd587',
    SV: 'dc705099-8a0f-402e-ba89-452aa0fbd5a1',
    US: '7ec4d223-c2dc-4535-9441-a2867428d5b0',
    UY: 'f01193a6-f0ca-4b76-913d-116775776b65',
    ZA: 'c2ade35a-b65e-4ce7-a612-44e8f855331e',
  },
  googleApiKeys: {
    AR: '',
    BR: 'AIzaSyC0dUyzJDH5qHctscqzVWBjR_fB6KskUwg',
    CL: '',
    CO: 'AIzaSyDzsBTAUydi0AnPYVUdRwYwwzWzfJTsGBY',
    DO: '',
    EC: 'AIzaSyCdp4jZTSSZbtE_e97IY5D8WFb4DguGFFI',
    MX: 'AIzaSyDbswZKZ3R_G5wzwxpYWeR9DmDm6Z7u6q4',
    PA: '',
    PE: 'AIzaSyA1wmKRkCq_-RBG-LdqJ_KCKBxjcU1H8sc',
    PY: '',
    US: '',
    ZA: '',
  },
  baseUrl: {
    AR: 'sdk.iad-03.braze.com',
    BE: 'sdk.fra-02.braze.eu',
    BO: 'sdk.iad-03.braze.com',
    BR: 'sdk.iad-03.braze.com',
    CA: 'sdk.iad-03.braze.com',
    CL: 'sdk.iad-03.braze.com',
    CO: 'sdk.iad-03.braze.com',
    DE: 'sdk.fra-02.braze.eu',
    DO: 'sdk.iad-03.braze.com',
    EC: 'sdk.iad-03.braze.com',
    ES: 'sdk.fra-02.braze.eu',
    GB: 'sdk.fra-02.braze.eu',
    HN: 'sdk.iad-03.braze.com',
    KR: 'sdk.iad-03.braze.com',
    MX: 'sdk.iad-03.braze.com',
    NL: 'sdk.fra-02.braze.eu',
    PA: 'sdk.iad-03.braze.com',
    PE: 'sdk.iad-03.braze.com',
    PY: 'sdk.iad-03.braze.com',
    SV: 'sdk.iad-03.braze.com',
    US: 'sdk.iad-03.braze.com',
    UY: 'sdk.iad-03.braze.com',
    ZA: 'sdk.iad-03.braze.com',
  },
  env: 'sit',
}
