import Analytics from 'analytics-node'
import { Segment } from '@bees-web/nfa-types'

export const initialize = (segment: Segment) => {
  return Object.keys(segment).reduce((results, country) => {
    try {
      return {
        ...results,
        [country]: new Analytics(segment[country].key, {
          flushAt: 1,
          host: segment[country].host,
        }),
      }
    } catch ({ message }) {
      console.error(message)
      console.error(`Problem initializing Segment for ${country.toUpperCase()}`)

      return { ...results }
    }
  }, {})
}
