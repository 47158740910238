/* eslint-disable security/detect-object-injection */
import { isObject } from './isObject'

/**
 * Deep merge two or more objects.
 * @param target
 * @param ...sources
 */
export function deepMerge<T>(target: T, ...sources): T {
  if (!sources.length) return target
  const source = sources.shift()

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} })
        deepMerge(target[key], source[key])
      } else {
        Object.assign(target, {
          [key]: typeof source[key] === 'undefined' ? null : source[key],
        })
      }
    }
  }

  return deepMerge(target, ...sources)
}
