/* eslint-disable security/detect-object-injection */
import dependencies from '../../config'

export type GetTrackabaleActions = () => string[]

const getTrackableActions: GetTrackabaleActions = () =>
  Object.keys(dependencies).flatMap(
    (key) => dependencies[key].actions.trackables
  )

export default getTrackableActions
