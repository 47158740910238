import { IConfigType } from '../interfaces/index'
import { nonProd as segment } from './segment'
import { nonProd as oneTrustKeys } from './oneTrust'

export const dev: IConfigType = {
  segment,
  oneTrustKeys,
  brazeKeys: {
    AR: 'bff4fc2e-0235-4b26-86e6-fd87ef16434c',
    BE: 'b7d9e27d-e2bd-46e7-812a-7780a4984a9b',
    BO: '59f31bf5-50a3-432c-8013-3ea06320fc53',
    BR: '443f942b-6372-4ab5-9429-ef9600f69706',
    CA: '3af361d9-7080-43a7-8ea6-76f3ea1db648',
    CL: '',
    CO: '5b69243f-264f-45c4-b19b-ec986d30674e',
    DE: 'fbd6a74d-7ece-4109-956a-d9e45e9410cc',
    DO: '1daba210-1953-40ec-8b69-0f6599f417c9',
    EC: 'd051e89a-a00c-424f-9dea-5b6d7c6fbfaa',
    ES: 'd7dfc4bf-a92c-4db8-954c-6906046342f5',
    GB: 'e0c7f4de-3baf-447f-a050-4054864d2564',
    HN: 'e2743a8f-4a0b-496f-a3ea-78f015f73e6c',
    KR: 'f3bc6e48-b32a-47a7-aab1-9079e6316723',
    MX: '9bafabda-c1e6-4483-b1d3-7cfe993ef363',
    NL: 'cf991f0c-e048-406c-9e7d-f4a9e8dc7220',
    PA: 'fb28c078-59e9-45b7-a177-356a7109f68f',
    PE: '289ad2a8-e4a5-454d-830a-4cd80d1f25a5',
    PY: '35581914-f913-414f-b8b1-081d749c8471',
    SV: '2bf2c7a8-1dbc-4134-a57b-a92914d23b9d',
    US: '63a4fe6b-356d-4b57-b11c-219e7b14257e',
    UY: 'ddf58b40-7734-4124-b187-28b1013a51a7',
    ZA: 'ddd7455f-0ecd-4a0c-8dd4-0008fc1557aa',
  },
  googleApiKeys: {
    AR: '',
    BR: 'AIzaSyDDVXMf4grBjJfLWVOp6inYjrOTAxUOAOo',
    CL: '',
    CO: 'AIzaSyBrSpq5p6jj2pRJQqF4OGEMrt4TlrT7MLs',
    DO: '',
    EC: 'AIzaSyDJuVlDuShqmmr_bgXEsWCNC334pMsyljE',
    MX: 'AIzaSyACh6FXdmC6hbTlq7I9xrKYsLmS1KQad58',
    PA: '',
    PE: 'AIzaSyA6SuNGDh-7C3ucu9ITP822mqEMqe_HEGo',
    PY: '',
    US: '',
    ZA: '',
  },
  baseUrl: {
    AR: 'sdk.iad-03.braze.com',
    BE: 'sdk.fra-02.braze.eu',
    BO: 'sdk.iad-03.braze.com',
    BR: 'sdk.iad-03.braze.com',
    CA: 'sdk.iad-03.braze.com',
    CL: 'sdk.iad-03.braze.com',
    CO: 'sdk.iad-03.braze.com',
    DE: 'sdk.fra-02.braze.eu',
    DO: 'sdk.iad-03.braze.com',
    EC: 'sdk.iad-03.braze.com',
    ES: 'sdk.fra-02.braze.eu',
    GB: 'sdk.fra-02.braze.eu',
    HN: 'sdk.iad-03.braze.com',
    KR: 'sdk.iad-03.braze.com',
    MX: 'sdk.iad-03.braze.com',
    NL: 'sdk.fra-02.braze.eu',
    PA: 'sdk.iad-03.braze.com',
    PE: 'sdk.iad-03.braze.com',
    PY: 'sdk.iad-03.braze.com',
    SV: 'sdk.iad-03.braze.com',
    US: 'sdk.iad-03.braze.com',
    UY: 'sdk.iad-03.braze.com',
    ZA: 'sdk.iad-03.braze.com',
  },
  env: 'dev',
}
