/* eslint-disable brace-style */
/* eslint-disable security/detect-object-injection */
import dependencies from '../../config'

export type GetCustomBlock = (name: string) => unknown

const getCustomBlock: GetCustomBlock = (name) => {
  const parts = name.split('.')

  if (parts.length === 2) {
    const [namespace, componentName] = parts
    const config = dependencies[namespace]
    if (config) {
      const components = config.components || {}
      return components[componentName] || null
    }
  } else if (parts.length === 1) {
    const allComponents = Object.keys(dependencies).reduce((previous, curr) => {
      return { ...previous, ...dependencies[curr].components }
    }, {})
    const [componentName] = parts
    return allComponents[componentName] || null
  }

  return null
}

export default getCustomBlock
