import { Segment } from '@bees-web/nfa-types'

export const prod: Segment = {
  AR: {
    key: 'cez92vFpRBlvJTJlZ2LatsN38i5vYnz3',
    host: 'https://api.segment.io',
  },
  BO: {
    key: 'BvubiDjOGc94QOpH8zbXT2Tgsfdkh4vN',
    host: 'https://api.segment.io',
  },
  BR: {
    key: 'IV2byXliiN7kGImmrX24MF0Qv99Kd7yP',
    host: 'https://api.segment.io',
  },
  CA: {
    key: 'X3vqUAnlEHeBFPgi31d2icXT78KGA5YU',
    host: 'https://api.segment.io',
  },
  CL: {
    key: '3gM1cxypSYcsLXBi1MIza926ihcnA0PU',
    host: 'https://api.segment.io',
  },
  CO: {
    key: 'bULl0KjskWikH1hUI7Bv99XVCdo0FvGf',
    host: 'https://api.segment.io',
  },
  DE: {
    key: 'R9aEkiUJ1hMkKNqaB6jFUoxTtPv4YARx',
    host: 'https://api.segment.io',
  },
  DO: {
    key: 'vDfJXkLaFw8Wu1M3q4axSgDlzDEIwFJw',
    host: 'https://api.segment.io',
  },
  EC: {
    key: '6Rko25g6OtuxHXygKdZuqjkliqCLTPCa',
    host: 'https://api.segment.io',
  },
  ES: {
     key: '',
     host: 'https://events.eu1.segmentapis.com',
  },
  GB: {
    key: 's3g1uTXa2BRWwLPBkgiGn8RZopyekHuk',
    host: 'https://events.eu1.segmentapis.com',
  },
  HN: {
    key: 'hi2tHZofZFniUNrVtpOGtTG5Vh2Qwmsj',
    host: 'https://api.segment.io',
  },
  KR: {
    key: 'ez0ASsZmMafU3RkC3gBBiBN3gCkdsdHl',
    host: 'https://api.segment.io',
  },
  MX: {
    key: 'H34G7fHCRc2vHc1iOgQLdRDH3HOZFUkb',
    host: 'https://api.segment.io',
  },
  PA: {
    key: 'GqIgzKS61NikeiCB5AzKDXvPe4CWxq6W',
    host: 'https://api.segment.io',
  },
  PE: {
    key: 'YKInZCsnTgOB6GsiF3YnokD19CyN2cDK',
    host: 'https://api.segment.io',
  },
  PY: {
    key: 'gydRI3QOrLYNJ2MFfSmeKQCMeAQPmQj9',
    host: 'https://api.segment.io',
  },
  SV: {
    key: 'humAeAAmskXXCsirAVem6T0wXhuUE8Sh',
    host: 'https://api.segment.io',
  },
  US: {
    key: 'KMVpRfQI8ivDWBLOTWalNrOyiPi85zLi',
    host: 'https://api.segment.io',
  },
  UY: {
    key: 'ttGmt6MUbzbevRkmT2ns7ADjiLfU88ih',
    host: 'https://api.segment.io',
  },
  ZA: {
    key: 'haVHu2Us7A1TiByOlCs719OJ0X9kbuQ5', // <- ZA SAB Connect; ZA BEES PROD -> 'bfxGBrQSndQ9GWSGyverP9JN2gFAQr17',
    host: 'https://api.segment.io',
  },
}
