import { IValueStreamConfiguration } from '@bees-web/nfa-types'
import checkout from '@bees-web/nfa-interactive-checkout'
import account from '@bees-web/nfa-interactive-account'
import postSales from '@bees-web/nfa-interactive-post-sales'
import payments from '@bees-web/nfa-interactive-payments'
import digitalServices from '@bees-web/nfa-interactive-digital-services'
import finances from '@bees-web/nfa-interactive-finances'
import downloadTemplate from '@bees-web/nfa-interactive-download-template'
import uploadOrder from '@bees-web/nfa-interactive-upload-order'
import linkCart from '@bees-web/web-interactive-link-cart'
import onboardingAutomation from '@bees-web/link-interactive-onboarding-automation'
import linkCurationship from '@bees-web/link-interactive-curationship'
import linkHome from '@bees-web/web-interactive-link-home'
import linkGlobal from '@bees-web/web-interactive-link-global'
import linkKam from '@bees-web/web-interactive-link-kam'
import linkOrderTracking from '@bees-web/web-interactive-link-order-tracking'
import algoSelling from '@bees-web/nfa-interactive-algo-selling'
import linkSfo from '@bees-web/web-interactive-link-sfo'
import rewards from '@bees-web/nfa-interactive-rewards'
import linkSearch from '@bees-web/web-interactive-link-search'
import linkProducts from '@bees-web/web-interactive-link-products'

// import products from '@bees-web/nfa-interactive-products'
// import home from '@bees-web/nfa-interactive-home'

export interface NFADependencies {
  [key: string]: IValueStreamConfiguration
}

const dependencies: NFADependencies = {
  [account.namespace]: account,
  [checkout.namespace]: checkout,
  [payments.namespace]: payments,
  [digitalServices.namespace]: digitalServices,
  [finances.namespace]: finances,
  [linkKam.namespace]: linkKam,
  // [products.namespace]: products,
  // [home.namespace]: home,
  [downloadTemplate.namespace]: downloadTemplate,
  [uploadOrder.namespace]: uploadOrder,
  [linkCart.namespace]: linkCart,
  [onboardingAutomation.namespace]: onboardingAutomation,
  [linkCurationship.namespace]: linkCurationship,
  [linkHome.namespace]: linkHome,
  [postSales.namespace]: postSales,
  [linkGlobal.namespace]: linkGlobal,
  [linkOrderTracking.namespace]: linkOrderTracking,
  [linkSfo.namespace]: linkSfo,
  [algoSelling.namespace]: algoSelling,
  [rewards.namespace]: rewards,
  [linkSearch.namespace]: linkSearch,
  [linkProducts.namespace]: linkProducts,
}

export default dependencies
