/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable security/detect-object-injection */
import React, { createElement } from 'react'
import { useSelector, useStore } from 'react-redux'
import { DeferredRenderer } from '../components/deferredRenderer'
import { IRenderBlocks } from './interfaces'
import blockMap from '@bees-web/nfa-components-react/src/block-map'
import * as Blocks from '@bees-web/nfa-components-react/src'
import { IApplicationState } from '@bees-web/nfa-types'
import sitemap from '../sitemap/sitemap.json'
import { mergeProperties as deepMerge } from '../utils/mergeProperties'
import { getCustomBlock } from '../utils/VL'

const CUSTOM_BLOCK_TAG = 'RenderComponent'

export const RenderBlocks = ({ block, interactiveMap }: IRenderBlocks) => {
  const store = useStore()
  const state = useSelector((state: IApplicationState) => ({
    // featureToggles: state.globals.featureToggles,
    cartQuantity: state.globals.cart.cartQuantity,
  }))
  const Block =
    block.blockType === CUSTOM_BLOCK_TAG
      ? getCustomBlock(block.name || '')
      : blockMap[block.blockType] || Blocks[block.blockType]

  if (!Block) {
    console.info('Block not found:', block)
    return null
  }

  if (block.deferred) {
    const {
      deferred,
      waitForState = [],
      renderIf = '',
      ...loadingBlock
    } = block

    return (
      <DeferredRenderer
        parent={block}
        url={deferred}
        loadingBlock={loadingBlock}
        interactiveMap={interactiveMap}
        waitForState={waitForState}
        renderIf={renderIf}
      />
    )
  }

  block.meta = block.meta || {}
  // block.meta.featureToggles = state.featureToggles

  if (block.attributes?.href) {
    const dynamicVariation =
      block.attributes.href.substring(
        0,
        block.attributes.href.lastIndexOf('/') + 0
      ) + '/[id]'

    block.meta.externalLink =
      block.attributes.href === '/' ||
      (!sitemap.routes[block.attributes.href] &&
        !sitemap.routes[dynamicVariation])

    if (sitemap.dynamicRoutes && sitemap.dynamicRoutes[dynamicVariation]) {
      block.meta.dynamicRoute = dynamicVariation
    }
  }

  if (block.blockType === 'cart_link') {
    block.meta.cartQuantity = state.cartQuantity
  }

  const interactivity = block.interactiveId
    ? interactiveMap[block.interactiveId]
    : false

  const { attributes } = interactivity || {}
  const { mergeProperties = false } = block
  const { mergeProperties: mergeProps = mergeProperties } = attributes || {}

  const props = mergeProps
    ? deepMerge(block.attributes, attributes)
    : { ...block.attributes, ...attributes }

  const renderBlocksProps = {
    key: block.name,
    ...block,
    ...interactivity,
    ...block.attributes,
    ...props,
    attributes: props,
  }

  return createElement(
    Block,
    { ...renderBlocksProps, store },
    block.blocks &&
      block.blocks.map((block, idx) => (
        <RenderBlocks
          key={block.name || `b-${idx}`}
          block={block}
          interactiveMap={interactiveMap}
        />
      ))
  )
}
