import React, { FC, ReactNode, useEffect } from 'react'
import { Provider, useDispatch, useSelector } from 'react-redux'
import { init } from '@bees-web/nfa-interactive-global/actions/authentication'
import { IApplicationState } from '@bees-web/nfa-types'
import { persistStore } from 'redux-persist'
import { Store } from 'redux'

interface PageContainer {
  [keys: string]: unknown
  store: Store
  children: ReactNode
}

const AuthContainer: FC<PageContainer> = ({ children, ...props }) => {
  const dispatch = useDispatch()
  const { authentication: auth } = useSelector(
    (state: IApplicationState) => state.globals
  )

  useEffect(() => {
    if (!auth.isAuthenticated) {
      dispatch(init(false))
    }
  }, [auth.isAuthenticated])

  return (
    <div {...props} className="main">
      {children}
    </div>
  )
}

const PageContainer: FC<PageContainer> = ({ ...props }) => {
  const { store } = props

  /* Redux-Persist is enabled by default.
   *
   * To disable Redux-Persist,
   * add the following environment variable to your .env file:
   *
   * DISABLE_PERSIST=true
   *
   * Do not forget to re-enable Redux-Persist by removing
   * this variable, or setting it to "false".
   */
  if (!process.env.DISABLE_PERSIST) {
    persistStore(store)
  }

  // expose store when run in Cypress
  useEffect(() => {
    if (window['Cypress']) {
      window['store'] = store
    }
  })

  return (
    <Provider store={store}>
      <AuthContainer {...props} />
    </Provider>
  )
}

export default PageContainer
