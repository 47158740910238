/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable security/detect-object-injection */
import React, { ReactElement } from 'react'
import Head from 'next/head'
import { useSelector, useDispatch } from 'react-redux'
import { IApplicationState, IBlock, IGlobalActions } from '@bees-web/nfa-types'
import Script, { ScriptProps } from 'next/script'
import { globalActions } from '../interactive'
import { RenderBlocks } from '../blocks'
import { IInteractiveMap } from '../blocks/interfaces'
import { getLocale } from '@bees-web/nfa-interactive-global/utils/getLocale'
import { ThunkDispatch } from 'redux-thunk'
import { AnyAction } from 'redux'
import { interactiveGlobalMap } from '../interactive/interactive-global-map'

export type UseInteractive = (
  dispatch: ThunkDispatch<IApplicationState, {}, AnyAction>,
  interactiveState: IApplicationState,
  globalActions: IGlobalActions,
  getLocale?: any //IGetLocaleFn
) => { [key: string]: any }

export type TPageProps = {
  stateSelector: (state: IApplicationState) => IApplicationState
  useInteractive: UseInteractive
  children: (props: {
    state: IApplicationState
    interactiveMap: IInteractiveMap
  }) => ReactElement | null
  globalNamespace?: string
}

const Page = ({
  stateSelector,
  useInteractive,
  children,
  globalNamespace,
}: TPageProps): ReactElement | null => {
  const state = useSelector((applicationState: IApplicationState) => {
    if (stateSelector) {
      return {
        ...applicationState,
        ...stateSelector(applicationState),
      }
    }

    return applicationState
  })
  const dispatch = useDispatch()

  const interactiveMap = {
    ...useInteractive(dispatch, state, globalActions, getLocale),
    ...interactiveGlobalMap({
      dispatch,
      state,
      globalNamespace,
    }),
  }

  return children({ state, interactiveMap })
}

export type TPageRenderProps = {
  name: string
  state: IApplicationState
  interactiveMap: IInteractiveMap
  scripts?: ScriptProps[]
}

const PageRender: React.FC<TPageRenderProps> = ({
  name,
  state: {
    globals: {
      cache: { pages },
    },
  },
  interactiveMap,
  scripts = [],
}) => {
  const page = pages?.[name] || {}
  const { meta = {}, blocks = [] } = page
  const { title, icon, description } = meta
  return (
    <div className="page-container">
      {title && (
        <Head>
          <title>{title}</title>
          <meta property="og:title" content={title} key="title" />
          {description && (
            <>
              <meta name="description" content={description} />
              <meta property="og:description" content={description} />
            </>
          )}
          {icon && (
            <>
              <link rel="icon" type="image/x-icon" href={icon} />
              <link rel="shortcut icon" type="image/x-icon" href={icon} />
            </>
          )}
        </Head>
      )}
      <>
        {scripts.map((script, idx) => (
          <Script key={`ps-${idx}`} {...script} />
        ))}
      </>

      {blocks.map((block: IBlock, idx: number) => (
        <RenderBlocks
          key={block.name || `pb-${idx}`}
          block={block}
          interactiveMap={interactiveMap}
        />
      ))}
    </div>
  )
}

Page.Render = PageRender

export default Page
