import { IOneTrustCountryKeys } from '../../interfaces'

export const nonProd: IOneTrustCountryKeys = {
  AR: {
    key: 'c0be0122-01b5-46e0-b29e-d3040ff38769-test',
    autoBlock: true,
    cookieList: false,
  },
  BE: {
    key: '9b76fe90-4570-4e0a-b6ee-f6e406131874-test',
    autoBlock: true,
    cookieList: false,
    couldBlockerTrackers: true,
  },
  BO: {
    key: 'be935a9c-79e8-469f-964e-e5d74485f59b-test',
    autoBlock: true,
    cookieList: false,
  },
  BR: {
    key: 'f81003dc-98da-4aea-82ee-8dc94686a6e1-test',
    autoBlock: true,
    cookieList: false,
  },
  CA: {
    key: '035defb4-442d-4ba3-8c1e-68ca6e3db7b6-test',
    autoBlock: true,
    cookieList: false,
  },
  CL: '',
  CO: {
    key: '09e5f9b9-3f0e-43ab-8d54-b8ab13006dae-test',
    autoBlock: true,
    cookieList: false,
  },
  DE: {
    key: '13c0faaf-45e0-4f53-a09c-e79cb0f8590b-test',
    keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
    autoBlock: true,
    cookieList: false,
    couldBlockerTrackers: true,
  },
  DO: {
    key: '5ad1670e-def9-4582-adb0-eda7ecf2aece-test',
    autoBlock: true,
    cookieList: false,
  },
  EC: {
    key: 'a13d857e-6ecd-4550-8308-b967b81da6d2-test',
    autoBlock: true,
    cookieList: false,
  },
  ES: {
    key: '47045cf5-74f2-4b07-9632-c1fceaea581a-test',
    keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
    autoBlock: true,
    cookieList: false,
    couldBlockerTrackers: true,
  },
  GB: {
    key: '73778fa7-f234-465f-ac52-02ce0e3d6cfc-test',
    keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
    autoBlock: true,
    cookieList: false,
    couldBlockerTrackers: true,
  },
  HN: {
    key: '810a18cd-c922-4a9a-b1c0-3013b5880a23-test',
    autoBlock: true,
    cookieList: false,
  },
  KR: {
    key: '04f6d6b6-cb30-4ff2-a8bb-c10921c84412',
    autoBlock: true,
    cookieList: false,
  },
  MX: {
    key: 'e1f85cf4-e18b-4afe-b3dc-a9c4008ec964-test',
    autoBlock: true,
    cookieList: false,
  },
  NL: {
    key: '82e25766-eb5c-42c0-b1fd-7855ec50c79a-test',
    autoBlock: true,
    cookieList: false,
    couldBlockerTrackers: true,
  },
  PA: {
    key: '95433d3e-1b0a-4ded-b21b-44f348447428-test',
    autoBlock: true,
    cookieList: false,
  },
  PE: {
    key: 'b7d0f091-dfa5-4ab8-80f9-c4f330e1d610-test',
    autoBlock: true,
    cookieList: false,
  },
  PY: {
    key: '3ce6e2f6-9b37-4d63-88b8-a391f79b76c0-test',
    autoBlock: true,
    cookieList: false,
  },
  SV: {
    key: 'c8108c51-52c7-4b8b-99e2-039ffea21a16-test',
    autoBlock: true,
    cookieList: false,
  },
  US: {
    key: 'f86d9220-9a08-470e-b71d-fb1c28df4426-test',
    autoBlock: true,
    cookieList: false,
  },
  UY: {
    key: '4d4a3223-1c58-4f90-b642-579063b8e553-test',
    autoBlock: true,
    cookieList: false,
  },
  ZA: {
    key: '049d3c28-8276-42e9-80d1-837fb542b89d-test',
    autoBlock: true,
    cookieList: false,
  },
}
