/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable security/detect-object-injection */
import { loadPage } from '@bees-web/nfa-interactive-global/actions/cache'
import { getLocale } from '@bees-web/nfa-interactive-global/utils/getLocale'
import Router from 'next/router'
import sitemap from '../sitemap/sitemap.json'
import { getCountryAndLanguage } from '../utils/locale'
import { checkDeeplink } from '../utils/BFF'
import { logger, LoggerProps } from '../utils/logger'
import wrapper from '../redux'
import cookies from 'next-cookies'
import { Store } from 'redux'
import { NextPageContext } from 'next'
import uuidv4 from '@bees-web/nfa-interactive-global/utils/uuidGenerator'
import { applicationsRoutes } from '../routing/applications-routes'

const routeToPageNames = (
  id: string,
  application = 'CUSTOMER'
): { [key: string]: string } => applicationsRoutes[application](id)

export interface PageProps {
  isServer: boolean
  isLocaleSupported: boolean
  name: string
  pathname: string
  language: string
  country: string
  ajsUserId: string
  ajsAnonymousId: string
}

const buildGetInitialProps =
  (store: Store) =>
  async (context: NextPageContext): Promise<PageProps> => {
    const { query, res } = context
    const { ajs_user_id, ajs_anonymous_id, enable_tracking, APPLICATION } =
      cookies(context)

    const id = query.id ? query.id.toString() : ''
    const { globals } = store.getState()
    const routeToPageName = routeToPageNames(id, APPLICATION)
    const pathname = context.asPath.split('?')[0]

    try {
      const { country, language: languageQuery } = getCountryAndLanguage({
        ...context,
        store,
      })

      const { isLocaleSupported, locale, language } = getLocale({
        country,
        language: languageQuery,
      })

      // Remove query params and trailing slash to get the correct pageName
      const pathKey = pathname === '/' ? '/' : pathname.replace(/\/$/, '')
      const pageName = routeToPageName[pathKey] || pathname

      const { accountId } = globals.authentication

      const query = {
        pageName,
        locale,
        accountId,
        entityId: id,
      }

      if (res && !ajs_anonymous_id && enable_tracking === 'true') {
        res.setHeader('Set-Cookie', `ajs_anonymous_id=${uuidv4()}; Path=/;`)
      }

      await checkDeeplink(context)

      const logMessageGetInitialParams: LoggerProps = {
        env: process.env.STAGE,
        page: 'withJoker.getInitialProps',
        message: `WithJoker => [pageName: ${pageName}], pathname:${pathname}, country:${country}, lang:${language}`,
        pageName,
        country,
        locale,
        accountId,
        entityId: id,
        pageRoute: pathname,
        language,
      }
      logger('info', logMessageGetInitialParams)

      await store.dispatch(
        loadPage({
          query,
          context,
          Router,
          sitemap,
          pageRoute: pathname,
        }) as any
      )

      return {
        isServer: !!context.req,
        isLocaleSupported,
        name: pageName,
        language,
        country,
        pathname: context.pathname,
        ajsUserId: ajs_user_id,
        ajsAnonymousId: ajs_anonymous_id,
      }
    } catch (e) {
      const logMessageRenderWithJokerCatchParams: LoggerProps = {
        env: process.env.STAGE,
        page: 'withJoker.getInitialProps',
        message: `WithJoker => Error: ${e?.message}`,
        pageName: null,
        country: null,
        language: null,
        locale: null,
        error: e,
        pathname,
      }
      logger('error', logMessageRenderWithJokerCatchParams)
      console.error(e)
    }
    return undefined
  }

export type WithJoker = <T>(
  EnhancedComponent: T & { getInitialProps?: any },
  store?: Store
) => T & { getInitialProps?: any }

const withJoker: WithJoker = (EnhancedComponent, store) => {
  EnhancedComponent.getInitialProps = store
    ? buildGetInitialProps(store)
    : wrapper.getInitialPageProps(buildGetInitialProps)
  return EnhancedComponent
}

export default withJoker
