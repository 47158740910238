/* eslint-disable security/detect-object-injection */
/* eslint-disable @typescript-eslint/camelcase */
import '../utils/log'
import React, { useEffect, useState } from 'react'
import Head from 'next/head'
import Cookies from 'js-cookie'
import Router from 'next/router'
import wrapper from '../redux'
import { PageContainer } from '../components'
import {
  routeChangeHandler,
  urlInterceptor,
} from '@bees-web/nfa-interactive-global/utils'
import { routeInitialize } from '@bees-web/nfa-interactive-global/actions/page'
import * as config from '../config'
import '@bees-web/nfa-components-react/src/themes/base.scss'
//import uuidv4 from '@bees-web/nfa-interactive-global/utils/uuidGenerator'
import { initialize } from '../segment'
import * as braze from '../utils/scripts/braze'
import newRelicTrackerScript from '../utils/scripts/newRelicTrackerScript'
import oneTrustConfigurationScript from '../utils/scripts/oneTrustConfigurationScript'
import useOneTrust from '../hooks/useOneTrust'
import newRelicString from '../public/newrelic-client'
import { PageProps } from '../components/withJoker'
import { AppProps } from 'next/app'
import { clearPersistStorage } from '../utils/storage'
//localfonts
import '/public/fonts/global.css'

const version = process.env.BUILD_BUILDID
const {
  segment = {},
  oneTrustKeys = {},
  brazeKeys = {},
  baseUrl = {},
} = config[process.env.STAGE] || {}

globalThis.segment = initialize(segment)

const ABIApp = ({ Component, ...others }: AppProps<PageProps>): JSX.Element => {
  const { store, props } = wrapper.useWrappedStore(others)
  const pageProps = props.pageProps as PageProps
  const { dispatch } = store
  const { ref, referrer } = props.router.query
  const { country, language, pathname, ajsUserId } = pageProps || {}

  const oneTrustValue = oneTrustKeys[country]

  const [initialized, setInitialized] = useState(false)
  const [oneScriptTrustLoaded, setOneScriptTrustLoaded] = useState(false)
  const { allowTracker } = useOneTrust(oneScriptTrustLoaded, oneTrustValue)

  globalThis.analytics = allowTracker ? globalThis.segment[country] : null

  if (!initialized && typeof window !== 'undefined') {
    urlInterceptor(location.href || '')
    clearPersistStorage(version)

    if (referrer) {
      dispatch(routeInitialize(origin, pathname, referrer))
    } else {
      const { origin, pathname } = window.location
      const referrer = ref ? `${origin}${ref}` : document.referrer
      const referrerUrl = referrer && referrer !== '' ? new URL(referrer) : null

      dispatch(
        routeInitialize(
          origin,
          pathname,
          referrerUrl ? referrerUrl.pathname : ''
        )
      )
    }

    setInitialized(true)
  }

  useEffect(() => {
    return routeChangeHandler(Router, store)
  }, [store])

  useEffect(() => {
    if (language) {
      document.cookie = `language=${language}; Path=/;`
    }
  }, [language])

  useEffect(() => {
    document.cookie = `country=${country}; Path=/;`
  }, [country])

  // Remove Imperva cookies for non prod -1
  useEffect(() => {
    if (process.env.STAGE !== 'prod') {
      const cookieList = []
      Object.keys(Cookies.get()).forEach((cookie) => {
        if (cookie.includes('incap_ses')) {
          cookieList.push(cookie)
        }
      })
      for (let i = 0; i < cookieList.length - 1; i++) {
        Cookies.remove(cookieList[i])
      }
    }
  }, [])

  /*
  if (!ajs_anonymous_id && enable_tracking === 'true') {
        res.setHeader('Set-Cookie', `ajs_anonymous_id=${uuidv4()}; Path=/;`)
      }
  */

  return (
    <div>
      <Head>
        {braze.initialize(
          ajsUserId,
          pathname,
          allowTracker ? brazeKeys[country] : null,
          baseUrl[country]
        )}

        {process.env.OPTIMIZELY_SDK_KEY && (
          <script
            src={`https://cdn.optimizely.com/datafiles/${process.env.OPTIMIZELY_SDK_KEY}.json/tag.js`}
          ></script>
        )}
      </Head>
      <PageContainer store={store}>
        <Component {...pageProps} />
        {oneTrustConfigurationScript(
          oneTrustValue,
          country,
          setOneScriptTrustLoaded
        )}
        {newRelicTrackerScript({
          allowTracker,
          newRelicString,
        })}
      </PageContainer>
    </div>
  )
}

export default ABIApp
