import { IOneTrustCountryKeys } from '../../interfaces'

export const prod: IOneTrustCountryKeys = {
  AR: {
    key: 'c0be0122-01b5-46e0-b29e-d3040ff38769',
    autoBlock: true,
    cookieList: false,
  },
  BE: '',
  BO: {
    key: 'a1f36230-8198-4d2d-9edc-6f26c3a5215a',
    autoBlock: true,
    cookieList: false,
  },
  BR: {
    key: 'f81003dc-98da-4aea-82ee-8dc94686a6e1',
    autoBlock: true,
    cookieList: false,
  },
  CA: {
    key: '035defb4-442d-4ba3-8c1e-68ca6e3db7b6',
    autoBlock: true,
    cookieList: false,
  },
  CL: '',
  CO: {
    key: '09e5f9b9-3f0e-43ab-8d54-b8ab13006dae',
    autoBlock: true,
    cookieList: false,
  },
  DE: {
    key: '7c772eb2-4639-4bd5-98e3-28fdd1a16afb',
    keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
    autoBlock: true,
    cookieList: false,
    couldBlockerTrackers: true,
  },
  DO: {
    key: '5ad1670e-def9-4582-adb0-eda7ecf2aece',
    autoBlock: true,
    cookieList: false,
  },
  EC: {
    key: 'a13d857e-6ecd-4550-8308-b967b81da6d2',
    autoBlock: true,
    cookieList: false,
  },
  ES: {
    key: 'b6b8faaa-7f44-433e-8c7f-afdbd6632b63',
    keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
    autoBlock: true,
    cookieList: false,
    couldBlockerTrackers: true,
  },
  GB: {
    key: '25296e9a-52af-4827-bc5c-0edeb1882188',
    keyNotificationPreferences: '11821760-d248-4745-b43e-10d85e89e988',
    autoBlock: true,
    cookieList: false,
    couldBlockerTrackers: true,
  },
  HN: {
    key: '810a18cd-c922-4a9a-b1c0-3013b5880a23',
    autoBlock: true,
    cookieList: false,
  },
  KR: {
    key: '8698005c-3db3-4802-843e-52ff0943c773',
    autoBlock: true,
    cookieList: false,
  },
  MX: {
    key: 'e1f85cf4-e18b-4afe-b3dc-a9c4008ec964',
    autoBlock: true,
    cookieList: false,
  },
  NL: '',
  PA: {
    key: '95433d3e-1b0a-4ded-b21b-44f348447428',
    autoBlock: true,
    cookieList: false,
  },
  PE: {
    key: 'b7d0f091-dfa5-4ab8-80f9-c4f330e1d610',
    autoBlock: true,
    cookieList: false,
  },
  PY: {
    key: '3ce6e2f6-9b37-4d63-88b8-a391f79b76c0',
    autoBlock: true,
    cookieList: false,
  },
  SV: {
    key: 'c8108c51-52c7-4b8b-99e2-039ffea21a16',
    autoBlock: true,
    cookieList: false,
  },
  US: {
    key: 'f86d9220-9a08-470e-b71d-fb1c28df4426',
    autoBlock: true,
    cookieList: false,
  },
  UY: {
    key: '4d4a3223-1c58-4f90-b642-579063b8e553',
    autoBlock: true,
    cookieList: false,
  },
  ZA: {
    key: '049d3c28-8276-42e9-80d1-837fb542b89d',
    autoBlock: true,
    cookieList: false,
  },
}
