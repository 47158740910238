import * as CAMPAIGN_ACTIONS from '@bees-web/nfa-interactive-campaigns/constants/trackableActions'
import * as CART_ACTIONS from '@bees-web/nfa-interactive-cart/constants/trackableActions'
import * as CHECKOUT_ACTIONS from '@bees-web/nfa-interactive-checkout/constants/trackableActions'
import * as CUSTOMER_EXPERIENCE_ACTIONS from '@bees-web/nfa-interactive-customer-experience/constants/trackableActions'
import * as GLOBALS_ACTIONS from '@bees-web/nfa-interactive-global/constants/trackableActions'
import * as HOME_ACTIONS from '@bees-web/nfa-interactive-home/constants/trackableActions'
import * as LINK_ACTIONS from '@bees-web/nfa-interactive-link/constants/trackableActions'
import * as PAYMENT_ACTIONS from '@bees-web/nfa-interactive-payments/constants/trackableActions'
import * as PRODUCT_ORDER_ACTIONS from '@bees-web/nfa-interactive-product-order/constants/trackableActions'
import * as PRODUCTS_ACTIONS from '@bees-web/nfa-interactive-products/constants/trackableActions'
import * as DEALS_ACTIONS from '@bees-web/nfa-interactive-deals/constants/trackableActions'

import { getTrackableActions } from '../../utils/VL'

export default new Set<string>([
  ...Object.values(CAMPAIGN_ACTIONS),
  ...Object.values(CART_ACTIONS),
  ...Object.values(CHECKOUT_ACTIONS),
  ...Object.values(CUSTOMER_EXPERIENCE_ACTIONS),
  ...Object.values(GLOBALS_ACTIONS),
  ...Object.values(HOME_ACTIONS),
  ...Object.values(LINK_ACTIONS),
  ...Object.values(PAYMENT_ACTIONS),
  ...Object.values(PRODUCT_ORDER_ACTIONS),
  ...Object.values(PRODUCTS_ACTIONS),
  ...Object.values(DEALS_ACTIONS),
  ...getTrackableActions(),
])
