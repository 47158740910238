import smartEval from './smart-eval'
import { ReplaceVariablesInString, ReplaceVariablesInObject } from './types'

const REGEX_STRING = '(\\<\\^([^\\^\\>]+)\\^\\>)'

/**
 * Will fill in a locolib with the delimiters: `<^^>`
 * @param locolib: the template text where the pathed-variable should be replaced.
 * @param replacer: the lookup by which the template is filled.
 * { `<^variableName^>`: 'replaceWith...'}
 * @return the filled out template string
 * @example:
 * @input:
 * template: '<^points^> points for <^username^>',
 * replacer: { points: '20,000', username: 'joe'}
 * @output '20,000 points for joe'
 */
export const replaceVariablesInString: ReplaceVariablesInString = (
  locolib = '',
  replacer,
  options
) => {
  if (!locolib.replace || !replacer) {
    return locolib
  }

  const locolibRegex = new RegExp(REGEX_STRING, 'g')
  const { waitForState = [] } = options
  const output = locolib.replace(
    locolibRegex,
    (_, _match, variableToReplace) =>
      variableToReplace &&
      (smartEval(
        replacer,
        variableToReplace,
        waitForState.includes(variableToReplace)
      ) as string)
  )
  return output
}

/**
 * Will go all through the object and fill in a string with the delimiters `<^^>` recursively
 * If the string is exactly the replace string, it will return as an object,
 * else, it will replace as the replaceVariablesInString
 * @depends replaceVariablesInString
 * @param data object which will be replaced
 * @param replacer the lookup by which the template is filled
 * { <^variableName^>: 'replaceWith...'}
 * @return the filled out object
 * @example1
 * @input: '<^points^>'
 * replacer: {points: '20,000', username: 'joe'}
 * @output {points: '20,000'} // as an object
 * @example2
 * @input: 'I have <^points^> points'
 * replacer: {points: '20,000', username: 'joe'}
 * @outupt 'I have 20,000 points'
 */
export const replaceVariablesInObject: ReplaceVariablesInObject = (
  data = {},
  replacer,
  options
) => {
  if (Object.keys(data).length === 0 || !replacer) {
    return data
  }

  const locolibRegex = new RegExp(REGEX_STRING, 'g')
  const { waitForState = [] } = options
  const output = {}
  Object.keys(data).map(key => {
    if (data[key] instanceof Object) {
      output[key] = replaceVariablesInObject(data[key])
    } else {
      const locolibMatch = data[key].toString().match(locolibRegex)
      if (locolibMatch && locolibMatch[0] === data[key]) {
        const locolibMatchString = locolibMatch[0].replace(/\^|\<|\>/gm, '')
        output[key] = smartEval(
          replacer,
          locolibMatchString,
          waitForState.includes(locolibMatchString)
        )
      } else {
        output[key] = replaceVariablesInString(data[key], replacer, options)
      }
    }
  })

  return output
}
