import { IConfigType } from '../interfaces/index'
import { nonProd as segment } from './segment'
import { nonProd as oneTrustKeys } from './oneTrust'

export const uat: IConfigType = {
  segment: {
    ...segment,
    US: {
      key: 'paRPnIAY5MY5VFChmX9OnhbZPAyO7PQE',
      host: 'https://api.segment.io',
    },
  },
  oneTrustKeys: {
    ...oneTrustKeys,
    KR: {
      key: '3112a3fd-72aa-48e3-b470-ae40adfe5ae2',
      autoBlock: true,
      cookieList: false,
    },
  },
  brazeKeys: {
    AR: '09f4b441-a64e-4276-8232-f484f1d91e4d',
    BE: 'a34fdec7-12f1-4de6-8712-a8594df704a8',
    BO: 'a658f918-c342-4577-8b63-9bceb49685d1',
    BR: '583a3e98-b4bf-47f7-a75a-f057054f1a98',
    CA: '921314b7-cd6d-49e6-8f38-1d3a52e7eeb2',
    CL: '',
    CO: '3cf53231-2b4b-4806-b702-46a3a16845e4',
    DE: 'b403d932-fe35-48a6-ab34-cc3f9c990a52',
    DO: '1310bd89-f053-4d47-946b-6e948f1539ef',
    EC: '3195af27-3fff-4a4c-885f-0cefe7e4a553',
    ES: '62417548-ae19-4a0f-9823-77f4db1a401f',
    GB: 'e0c7f4de-3baf-447f-a050-4054864d2564',
    HN: '7140fb99-c5c3-415b-8baf-85a30ff8068c',
    KR: '56aacb3e-89f3-40fe-9549-6ecfc104232e',
    MX: 'c6db138e-b5df-4f01-b217-fa0885620a4b',
    NL: 'b022f961-0f05-4ee8-a1ad-1e0ee58501b4',
    PA: 'b4e34b24-d9f7-42f4-9ef6-e7980be05e4c',
    PE: '036df39c-82bf-4f3b-a8e9-b89101e49954',
    PY: '7bf2ea95-918b-45a2-9c43-67ef433affb4',
    SV: '56ca2bfb-6e85-47b1-92dc-2ab25508eabf',
    US: 'c74f5062-ceb7-4aa9-a07d-bb8657e137c6',
    UY: 'c02b595e-316f-4463-b35d-a0c1335b7473',
    ZA: '0f3ac083-f2b3-4efe-866a-56aacdb13b2f',
  },
  googleApiKeys: {
    AR: '',
    BR: 'AIzaSyAKkAKZFvReMSU6rxkxGbiNfCVotS9G4oE',
    CL: '',
    CO: 'AIzaSyAqdLxsccvvKAFVcM4cfJMM0UkOJhFEt08',
    DO: '',
    EC: 'AIzaSyBnc2t_h3fWf626CBLbPUbQuaD1dOAW5Xs',
    MX: 'AIzaSyDWp9RYy_bvOg4fuznZUkFd3xWvYKhldQY',
    PA: '',
    PE: 'AIzaSyB00swVoykGzGx7-0jmje7SqIvgOxS7RRM',
    PY: '',
    US: '',
    ZA: '',
  },
  baseUrl: {
    AR: 'sdk.iad-03.braze.com',
    BE: 'sdk.fra-02.braze.eu',
    BO: 'sdk.iad-03.braze.com',
    BR: 'sdk.iad-03.braze.com',
    CA: 'sdk.iad-03.braze.com',
    CL: 'sdk.iad-03.braze.com',
    CO: 'sdk.iad-03.braze.com',
    DE: 'sdk.fra-02.braze.eu',
    DO: 'sdk.iad-03.braze.com',
    EC: 'sdk.iad-03.braze.com',
    ES: 'sdk.fra-02.braze.eu',
    GB: 'sdk.fra-02.braze.eu',
    HN: 'sdk.iad-03.braze.com',
    KR: 'sdk.iad-03.braze.com',
    MX: 'sdk.iad-03.braze.com',
    NL: 'sdk.fra-02.braze.eu',
    PA: 'sdk.iad-03.braze.com',
    PE: 'sdk.iad-03.braze.com',
    PY: 'sdk.iad-03.braze.com',
    SV: 'sdk.iad-03.braze.com',
    US: 'sdk.iad-06.braze.com',
    UY: 'sdk.iad-03.braze.com',
    ZA: 'sdk.iad-03.braze.com',
  },
  env: 'uat',
}
