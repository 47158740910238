/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/display-name */
import React, { useEffect } from 'react'
import Page from './page'
import { pageTracking } from '@bees-web/nfa-interactive-global/actions/page'
import { useDispatch } from 'react-redux'
import { SimplePageArgs } from '../interfaces'

export const getPageTracking =
  (segmentPageName) =>
  ({ name: payloadName, referrer, search_query, field_title, screen_name }) => {
    return {
      event: segmentPageName || payloadName,
      name: segmentPageName || payloadName,
      referrer,
      search_query: search_query || window.location.search || null,
      field_title: field_title || null,
      url: window.location.href,
      screen_name: screen_name || null,
    }
  }

const SimplePage =
  ({
    stateSelector,
    useInteractive,
    segmentPageName = null,
    scripts = [],
    globalNamespace,
  }: SimplePageArgs) =>
  ({ name }: { name: string }) => {
    const dispatch = useDispatch()
    useEffect(() => {
      dispatch(pageTracking(getPageTracking(segmentPageName)))
    }, [dispatch])
    return (
      <Page
        stateSelector={stateSelector}
        useInteractive={useInteractive}
        globalNamespace={globalNamespace}
      >
        {({ state, interactiveMap }) => (
          <Page.Render
            name={name}
            state={state}
            interactiveMap={interactiveMap}
            scripts={scripts}
          />
        )}
      </Page>
    )
  }
export default SimplePage
